<template>
  <div class="p-0">
    <highcharts v-show="isChart" :options="chartOptions" ref="highcharts"></highcharts>

    <div class="mt-4">
      <div class="form-label">Compare From</div>
      <date-picker
          v-model="compareData.fromDate"
          :default-value="startDate"
          :disabled-date="disabledStartDate"
          :format="format"
          value-type="YYYY-MM-DD"
      ></date-picker>
    </div>

    <div class="mt-3">
      <div class="form-label">Compare To</div>
      <date-picker
          v-model="compareData.toDate"
          :default-value="endDate"
          :disabled-date="disabledEndDate"
          :format="format"
          value-type="YYYY-MM-DD"
      ></date-picker>
    </div>

    <div class="mt-3">
      <div class="form-label">Compare For</div>
      <multiselect multiple v-model="compareData.companies" :options="lenders"
                   track-by="id" label="name"/>
    </div>

    <div class="mt-3">
      <div class="form-label">Compare</div>
      <select v-model="compareData.compare" class="form-select">
        <option v-for="compare in compares" :key="compare.key" :value="compare.key">
          {{ compare.value }}
        </option>
      </select>
    </div>

   <p class="mt-4"> <small class="pl-2 pb-3 pt-2 font-bold block text-danger" v-if="companyError">{{ companyError }}</small></p>

    <div class="mt-3">
      <button :disabled="isLoading" class="btn btn-warning w-25" @click="graph">
        {{ !isLoading ? 'Display Graph' : 'Loading...' }}
      </button>
      <button class="btn btn-primary w-25" @click="reset">Clear</button>
    </div>
  </div>
</template>

<script>
import {Chart} from 'highcharts-vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Multiselect from 'vue-multiselect'

export default {
  name: "MortgageCompare",
  props: ['lenders', 'fromDate', 'toDate'],
  components: {
    DatePicker,
    Multiselect,
    highcharts: Chart
  },

  data() {
    return {
      companyError: null,
      isLoading: false,
      isChart: false,
      startDate: null,
      endDate: null,
      format: 'DD MMMM YYYY',

      defaultCompareData: {
        fromDate: '2002-01-04',
        toDate: '2023-12-31',

        companies: null,
        compare: 'rate_floating',
      },
      compareData: {
        fromDate: null,
        toDate: null,

        companies: null,
        compare: 'rate_floating',
      },
      compares: [
        {key: 'rate_floating', value: 'Floating Rates'},
        {key: 'rate_6month', value: '6 Months'},
        {key: 'rate_1year', value: '1 Year'},
        {key: 'rate_2year', value: '2 Year'},
        {key: 'rate_3year', value: '3 Year'},
        {key: 'rate_4year', value: '4 Year'},
        {key: 'rate_5year', value: '5 Year'},
      ],

      colors: ['#058BD7', '#52AA34', '#794BDA', '#F58BD7', '#520A34', '#294BFA'],

      seriesData: {
        name: '',
        type: 'line',
        color: '#000',
        tooltip: {
          valueDecimals: 2
        },
        turboThreshold: 5000,
        data: [],
      },

      defaultChartOptions: {
        chart: {
          zoomType: 'x'
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          min: 0,
          title: {
            text: ''
          }
        },
        legend: {
          enabled: true
        },
        title: {
          text: ''
        },
        series: [],
        credits: {
          enabled: false
        },
      },

      chartOptions: {
        chart: {
          zoomType: 'x'
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          min: 0,
          title: {
            text: ''
          }
        },
        legend: {
          enabled: true
        },
        title: {
          text: ''
        },
        series: [],
        credits: {
          enabled: false
        },
      }
    }
  },
  mounted() {
    this.compareData.fromDate = this.fromDate;
    this.compareData.toDate = this.toDate;
  },
  methods: {
    renderChart() {
      if (this.$refs.highcharts) {
        //
      }
    },

    reset() {
      this.compareData = this.defaultCompareData
      this.chartOptions = this.defaultChartOptions;
      this.isChart = false;
    },

    graph() {
      if(this.compareData.companies && this.compareData.companies.length > 5) {
        this.companyError = 'You may only include up to 5 lenders in your graph';
      } else if (!(this.compareData.toDate && this.compareData.fromDate)) {
        this.companyError = 'Date fields cannot be empty'
      } else if (!this.compareData.companies) {
        this.companyError = 'You must select at least one company';
      } else {
        this.isLoading = true;
        this.chartOptions.series = [];

        axios({
          method: 'POST',
          url: '/mortgage/graph',
          data: this.compareData
        }).then(res => {
          for (const key in res.data.rates) {
            let seriesData = {...this.seriesData};
            let company = this.compareData.companies.find(o => o.id == key);
            seriesData.name = company.name;
            seriesData.color = this.colors[this.chartOptions.series.length];
            seriesData.data = res.data.rates[key];
            this.chartOptions.series.push(seriesData);
          }
          this.isLoading = false;
          this.isChart = true;
        }).catch(err => {
          this.isLoading = false;
          console.log({error: err})
        })
      }

      setTimeout(() => {
        this.companyError = null
      }, 2500)
    },

    disabledStartDate(date) {
      return (
          this.fromDate &&
          new Date(date).setHours(0, 0, 0, 0) <
          new Date(this.fromDate).setHours(0, 0, 0, 0)
      );
    },
    disabledEndDate(date) {
      console.log(this.toDate);
      return (
          this.toDate &&
          new Date(date).setHours(0, 0, 0, 0) >
          new Date(this.toDate).setHours(0, 0, 0, 0)
      );
    },
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
