<template>
    <div id="headerAdvert" v-if="advertImg">
        <div class="flex bg-light h-auto">
            <div class="d-flex flex-column align-items-center">
                <div class="position-relative">
                    <img class="img-fluid py-4"
                         :src="advertImg"
                         alt="">
                    <button @click="hideAdvert"
                            class="btn bg-light border-0 rounded-0">
                        <i class="fa fa-close"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            advertImg: ''
        };
    },
    mounted() {
        axios.get("/header-advert").then(response => {
            this.advertImg = response.data.image;
        });
    },
    methods: {
        hideAdvert() {
            // hide for 3 hours
            document.cookie = "hide_header_advert=true; expires=" + new Date(new Date().getTime() + 1000 * 60 * 60 * 3).toUTCString() + "; path=/";
            this.advertImg = '';
        }
    }
};
</script>

<style scoped>
button {
    position: absolute;
    top: 1.5rem;
    right: 0;
    opacity: 0.5;
}
</style>
