<template>
<div>
  <div class="flex">
    <a class="fs-6 mr-3 text-secondary" href="/mortgage-rates"><small>All Rates</small> </a> |
    <a class="fs-6 text-secondary" href="/compare-mortgage-rates"><small>Compare Rates</small></a>
  </div>
  <div class="p-1 justify-content-center bg-light">
    <div class="d-flex">
      <small class="mx-1">From: </small>
      <date-picker v-model="compareData.fromDate" valueType="format"></date-picker>
    </div>

    <div class="d-flex mt-2">
      <small class="mx-2 pr-2">To: </small>
      <date-picker class="" v-model="compareData.toDate" valueType="format"></date-picker>
    </div>

    <div class="d-flex mt-2">
      <small class="mx-2">For: </small>
      <multiselect class="text-sm" multiple v-model="compareData.companies" :options="lenders"
                   track-by="id" label="name"/>
    </div>

    <div class="row mt-2">
      <small style="font-size: 10px">To graph multiple lenders, hold down Ctrl key while clicking in list box</small>
    </div>

    <div class="mt-4 justify-content-center">
      <button class="btn btn-primary btn-sm" @click="graph">Display Graph</button>
    </div>

  </div>
</div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Multiselect from 'vue-multiselect'

export default {
    name: "MortgageCompareWidget",
    props: ['lenders','compareWidget'],
   components: { DatePicker,Multiselect },
    data() {
        return {
            defaultCompareData: {
              fromDate: '2002-01-04',
              toDate: '2023-12-31',
              companies: null,
              compare: 'rate_floating',
            },

            compareData: {
              fromDate: '2002-01-04',
              toDate: '2023-12-31',
              companies: null,
              compare: 'rate_floating',
            }
        }
    },

  created(){
    console.log(this.compareWidget)
  },


    methods: {
        graph(){
          axios({
            method: 'POST',
            url: '/mortgage/graph-widget',
            data: this.compareData
          }).then(res => {
            console.log(res)
            window.location.href = '/compare-mortgage-rates';
          }).catch(err=> {
            console.log({error: err})
          })
            // location.href = `/compare-mortgage-rates`
        },
    }
}
</script>

<style scoped>
small {
    font-size: 12px;
}
</style>
