<template>
    <div class="mb-5 mt-2">

        <div class="card card-body bg-light mb-3">
            <div class="d-flex bd-highlight">
                <div class="p-2 flex-fill bd-highlight">
                    <label class="w-full mb-2 mx-2 text-secondary" for="changesFrom">Changes from last</label>
                    <select id="changesFrom" @change="changesFromDate" class="w-50 form-control" v-model="changesFrom">
                        <option v-for="option in changesFromOptions" :key="option.key"
                                :value="option.key">{{ option.value }}
                        </option>
                    </select>
                </div>

                <div class="p-2 flex-fill bd-highlight">
                    <label class="w-full mb-2 mx-2 text-secondary" for="changesBank">Lender bank type</label>
                    <select id="changesBank" @change="changesBank" class="w-50 form-control" v-model="selectedBankType">
                        <option v-for="option in changesBankTypes" :key="option.key"
                                :value="option.key">{{ option.value }}
                        </option>
                    </select>
                </div>
            </div>
        </div>


        <div v-show="isLoading" class="flex pb-2">
            <img height="20" src="/images/spinner.gif" alt="">
            <span>Loading...</span>
        </div>

        <table class="table">
            <thead>
            <tr>
                <th class="w-16 bg-light" v-for="column in columns" :key="column.sortKey">
                    <div class="flex">
                        <small>{{ column.label }}</small><br>

                        <a class="" @click="sortTable(column.sortKey,'desc')">
                            <i :class="currentSortDir === 'desc' && currentSort === column.sortKey ? 'text-success': ''"
                               class="fa fa-angle-up ml-1" style="font-size: smaller"></i>
                        </a>

                        <a class="" @click="sortTable(column.sortKey,'asc')">
                            <i :class="currentSortDir === 'asc' && currentSort === column.sortKey ? 'text-danger': ''"
                               class="fa fa-angle-down ml-1" style="font-size: smaller"></i>
                        </a>
                    </div>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr :class="{'bg-light': index % 2 === 0, 'h-5': true}" v-for="(rate,index) in filteredRates"
                :key="rate.id">
                <td class="lender">{{ rate.lender_name }}</td>
                <td>{{ rate.rate_floating }}</td>
                <td>{{ rate.rate_6month }}</td>
                <td>{{ rate.rate_1year }}</td>
                <td>{{ rate.rate_18month }}</td>
                <td>{{ rate.rate_2year }}</td>
                <td>{{ rate.rate_3year }}</td>
                <td>{{ rate.rate_4year }}</td>
                <td>{{ rate.rate_5year }}</td>
                <td>{{ rate.rate_rc }}</td>
            </tr>
            </tbody>
        </table>

        <h5 v-if="filteredRates.length < 1" class="text-center">No Rates</h5>
    </div>
</template>

<script>
export default {
    name: "MortgageRate",

    data() {
        return {
            selectedBankType: -1,
            isLoading: false,
            rates: [],
            filteredRates: [],
            changesFrom: 'fourWeek',

            changesBankTypes: [
                {key: -1, value: 'All Lenders'},
                {key: 2, value: 'Major Banks'},
                {key: 1, value: 'Minor Banks'},
                {key: 0, value: 'Non Banks'},
            ],

            changesFromOptions: [
                {key: 'oneDay', value: 'One Day'},
                {key: 'oneWeek', value: 'One Week'},
                {key: 'twoWeek', value: 'Two Weeks'},
                {key: 'fourWeek', value: 'Four Weeks', selected: true},
                {key: 'threeMonth', value: 'Three Months'},
                {key: 'sixMonth', value: 'Six Months'},
            ],

            columns: [
                {label: 'Name', sortKey: 'lender_name'},
                {label: 'Floating', sortKey: 'rate_floating'},
                {label: '6 Mth', sortKey: 'rate_6month'},
                {label: '1 Year', sortKey: 'rate_1year'},
                {label: '18 Mth', sortKey: 'rate_18month'},
                {label: '2 Year', sortKey: 'rate_2year'},
                {label: '3 Year', sortKey: 'rate_3year'},
                {label: '4 Year', sortKey: 'rate_4year'},
                {label: '5 Year', sortKey: 'rate_5year'},
                {label: 'RC', sortKey: 'rate_rc'},
            ],
            currentSort: '',
            currentSortDir: 'asc',
        };
    },

    mounted() {
        this.getRates(this.changesFrom)
    },

    methods: {
        changesFromDate() {
            this.getRates(this.changesFrom);
        },

        changesBank() {
            if (this.selectedBankType === -1) {
                this.filteredRates = this.rates;
            } else {
                this.filteredRates = this.rates.filter(rate => rate.lender_bank_type === this.selectedBankType);
            }
        },

        getRates(dateType) {
            this.isLoading = true;

            axios({
                method: 'GET',
                url: `/mortgage/rates?dateType=${dateType}`
            }).then(res => {
                this.rates = res.data.data
                this.filteredRates = res.data.data;

                this.sortTable()
                this.isLoading = false;
            }).catch(err => {
                this.isLoading = false;
                console.log({error: err})
            })
        },

        sortTable(s, dir) {
            if (s === this.currentSort) {
                this.currentSortDir = dir;
            }
            this.currentSort = s;
            this.filteredRates.sort((a, b) => {
                let sortA, sortB;
                if (this.currentSort === 'lender_name') {
                    sortA = a[this.currentSort];
                    sortB = b[this.currentSort];
                    let modifier = (this.currentSortDir === 'asc') ? 1 : -1;
                    if (sortA < sortB) return -1 * modifier;
                    if (sortA > sortB) return modifier;
                } else {
                    if (this.currentSortDir === 'asc') {
                        sortA = (a[this.currentSort] == null) ? Number.MAX_SAFE_INTEGER : parseFloat(a[this.currentSort]);
                        sortB = (b[this.currentSort] == null) ? Number.MAX_SAFE_INTEGER : parseFloat(b[this.currentSort]);
                        return sortA - sortB;
                    } else {
                        sortA = (a[this.currentSort] == null) ? Number.MIN_SAFE_INTEGER : parseFloat(a[this.currentSort]);
                        sortB = (b[this.currentSort] == null) ? Number.MIN_SAFE_INTEGER : parseFloat(b[this.currentSort]);
                        return sortB - sortA;
                    }
                }
            });
        },
    },
}
</script>

<style scoped>
td {
    font-size: 12px;
    color: grey;
    font-weight: bold;
}

th {

    font-size: 14px;
    font-family: Arial;
    font-weight: bold;
}
</style>
