<template>
  <div>
      <div class="flex">
          <a class="fs-6 mr-3 text-secondary" href="/mortgage-rates"><small>All Rates</small> </a> |
          <a class="fs-6 text-secondary" href="/compare-mortgage-rates"><small>Compare Rates</small></a>
      </div>
      <table class="table">
          <thead>
          <tr>
              <th class="w-16" v-for="column in columns" :key="column.sortKey">
                  <div class="flex">
                      <small style="font-size: small;margin-right: 2px">{{ column.label }}</small>
                      <br>
                      <a @click="sortTable(column.sortKey,'desc')">
                          <i style="font-size: x-small" :class="currentSortDir === 'desc' && currentSort === column.sortKey ? 'text-success': ''"
                             class="fa fa-angle-up"></i>
                      </a>

                      <a  @click="sortTable(column.sortKey,'asc')">
                          <i style="font-size: x-small" :class="currentSortDir === 'asc' && currentSort === column.sortKey ? 'text-danger': ''"
                             class="fa fa-angle-down "></i>
                      </a>
                  </div>
              </th>
          </tr>
          </thead>
          <tbody>
          <tr class="bg-light h-5" v-for="(rate,index) in rates" :key="rate.id">
              <td class="lender">{{ rate.lender_name }}</td>
              <td>{{ rate.rate_floating }}</td>
              <td>{{ rate.rate_1year }}</td>
              <td>{{ rate.rate_2year }}</td>
              <td>{{ rate.rate_3year }}</td>
          </tr>
          </tbody>
      </table>

      <p v-if="rates.length < 1"  class="text-center">No Rates</p>
  </div>
</template>

<script>
export default {
    data() {
        return {
            rates: [],
            columns: [
                {label: 'Lender', sortKey: 'lender_name'},
                {label: 'Flt', sortKey: 'rate_floating'},
                {label: '1Yr', sortKey: 'rate_1year'},
                {label: '2Yr', sortKey: 'rate_2year'},
                {label: '3Yr', sortKey: 'rate_3year'},
            ],
            currentSort: '',
            currentSortDir: 'asc',
        };
    },

    mounted() {
        this.getRates()
    },

    methods: {
        getRates() {
            axios({
                method: 'get',
                url: '/mortgage/rates'
            }).then(res => {
                this.rates = res.data.data
                this.sortTable()
            }).catch(err => {
                console.log({error: err})
            })
        },

        sortTable(s, dir) {
            if (s === this.currentSort) {
                this.currentSortDir = dir;
            }
            this.currentSort = s;
            this.rates.sort((a, b) => {
                let sortA, sortB;
                if (this.currentSort === 'lender_name') {
                    sortA = a[this.currentSort];
                    sortB = b[this.currentSort];
                    let modifier = (this.currentSortDir === 'asc') ? 1 : -1;
                    if (sortA < sortB) return -1 * modifier;
                    if (sortA > sortB) return modifier;
                } else {
                    if (this.currentSortDir === 'asc') {
                        sortA = (a[this.currentSort] == null) ? Number.MAX_SAFE_INTEGER : parseFloat(a[this.currentSort]);
                        sortB = (b[this.currentSort] == null) ? Number.MAX_SAFE_INTEGER : parseFloat(b[this.currentSort]);
                        return sortA - sortB;
                    } else {
                        sortA = (a[this.currentSort] == null) ? Number.MIN_SAFE_INTEGER : parseFloat(a[this.currentSort]);
                        sortB = (b[this.currentSort] == null) ? Number.MIN_SAFE_INTEGER : parseFloat(b[this.currentSort]);
                        return sortB - sortA;
                    }
                }
            });
        },
    },
}
</script>

<style scoped>
td {
    font-size: 12px;
    color: grey;
    font-weight: bold;
}
th {
    font-size: 10px;
    font-weight: bold;
}

.lender {
    text-align: left;
    white-space: normal;
    font-size: 10px;
    padding: 2px 2px
}

</style>
