import HeaderAdvert from "./components/HeaderAdvert.vue";

require('./bootstrap');

import Vue from 'vue';
import MortgageRates from './components/Mortgages/MortgageRate';
import MortgageCompare from './components/Mortgages/MortgageCompare';
import MortgageRatesWidget from './components/Mortgages/MortgageRatesWidget';
import MortgageCompareWidget from './components/Mortgages/MortgageCompareWidget';

Vue.component('rates',MortgageRates)
Vue.component('compare',MortgageCompare)
Vue.component('rates-widget',MortgageRatesWidget)
Vue.component('compare-widget',MortgageCompareWidget)
Vue.component('header-advert',HeaderAdvert)

const app = new Vue({
    el: '#app',
});
